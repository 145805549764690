"use client"

import { Tooltip, TooltipContent, TooltipTrigger } from "./ui/tooltip"

export default function BoilerplateCardIcon({
  name,
  icon,
}: {
  name: string
  icon: any
}) {
  return (
    <>
      <Tooltip key={`tooltip-techno-icon-${name}`}>
        <TooltipTrigger asChild>
          <div className="h-fit cursor-default rounded-md bg-neutral-200 p-1 font-medium">
            {icon}
          </div>
        </TooltipTrigger>
        <TooltipContent>{name}</TooltipContent>
      </Tooltip>
    </>
  )
}
