"use client"

export default function DynamicArrow() {
  return (
    <div className="absolute lg:flex hidden right-20 top-5 items-end gap-4">
      <svg
        className="h-20 w-20 scale-x-[-1] transform"
        viewBox="0 0 106 67"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.746735 34.5184C12.2021 40.1368 19.6014 41.495 29.6823 33.0404C36.0718 27.6817 42.1501 19.6964 45.0862 11.851C46.7881 7.30351 40.6898 12.0318 39.6346 13.0432C27.4261 24.7444 22.6035 43.8023 31.4757 58.851C37.2579 68.6586 47.5163 67.3307 56.3712 62.5164C69.8751 55.1746 77.5257 39.8696 80.2223 25.2514C81.1193 20.3886 84.6829 5.82289 78.8378 2.65785C73.9949 0.0354855 72.0976 9.61317 71.6643 12.5431C71.0465 16.7204 70.0313 25.7894 73.1645 29.1607C77.6582 33.9957 86.6118 27.5772 89.8445 24.3227C95.9834 18.1424 100.46 9.32842 104.943 1.91147C105.273 1.36535 101.764 3.3297 101.147 3.66784C99.3793 4.63577 97.2951 4.84168 95.6089 5.96364C94.3579 6.79605 88.7443 12.6083 93.6037 10.5208C95.3164 9.785 103.323 1.07071 103.701 1.3055C105.246 2.26341 104.699 13.9403 104.581 16.0977C104.296 21.2928 97.1835 12.0461 96.4366 10.6636"
          stroke="currentColor"
          stroke-width="4"
          stroke-linecap="round"
        />
      </svg>
      <p className="flex flex-col  font-semibold text-xl">
        Bookmark this page <br />
        for later  🌟
      </p>
    </div>
  )
}
